<template>
  <v-container class="py-16">
    <v-row justify="center">
      <!-- left column -->
      <v-col cols="12" sm="7" md="8" xl="9">
        <v-card color="surface" class="pa-4 mb-6" flat>
          <div class="d-flex align-center justify-space-between mb-4">
            <div class="text-h5 font-weight-bold">
              <v-icon v-text="'$hammer2'" left />
              <span class="saira"> Últimas notícias </span>
            </div>
          </div>

          <LastNews />
        </v-card>

        <!-- my leagues -->
        <v-card color="surface" class="pa-4 mb-6" flat>
          <div class="d-flex align-center justify-space-between">
            <div class="text-h5 font-weight-bold">
              <v-icon v-text="'$hammer2'" left />
              <span class="saira"> Minhas ligas </span>
            </div>
          </div>

          <loader-content v-if="loading.leagues" />

          <v-slide-group v-else-if="myLeagues.length > 0" show-arrows>
            <v-slide-item
              v-for="(item, i) in myLeagues"
              :key="i"
              class="my-6 zoom"
            >
              <router-link
                :to="`/league-details/${item.id}`"
                class="text-decoration-none"
              >
                <div class="mx-2 text-center pointer">
                  <v-card class="rounded-lg mb-2" color="accent" width="148">
                    <v-img :src="item.image" :aspect-ratio="4 / 4" />
                  </v-card>

                  <span class="text-caption overtext--text font-weight-bold">
                    {{ item.name }}
                  </span>
                </div>
              </router-link>
            </v-slide-item>
          </v-slide-group>

          <!-- empty -->
          <div v-else class="d-flex flex-column align-center mt-4">
            <span class="text-body-1 text-center subtext--text mb-4">
              Você não está participando de nenhuma liga ativa
            </span>

            <v-btn
              class="rounded-l-0 rounded-r-lg text-none"
              color="primary"
              to="/leagues"
            >
              <v-icon left> mdi-arrow-right </v-icon>

              Participar
            </v-btn>
          </div>
        </v-card>

        <!-- my teams -->
        <v-card color="surface" class="pa-4" flat>
          <div class="d-flex align-center justify-space-between">
            <div class="text-h5 font-weight-bold">
              <v-icon v-text="'$hammer2'" left />
              <span class="saira"> Minhas Equipes </span>
            </div>
          </div>

          <loader-content v-if="loading.teams" />

          <v-slide-group v-else-if="myTeams.length > 0" show-arrows>
            <v-slide-item
              v-for="(item, i) in myTeams"
              :key="i"
              class="my-6 zoom"
            >
              <router-link
                :to="`/team-details/${item.id}`"
                class="text-decoration-none"
              >
                <div class="mx-2 text-center pointer">
                  <v-card class="rounded-lg mb-2" color="accent" width="148">
                    <v-img :src="item.logo" :aspect-ratio="4 / 4" />
                  </v-card>

                  <span class="text-caption overtext--text font-weight-bold">
                    {{ item.name }}
                  </span>
                </div>
              </router-link>
            </v-slide-item>
          </v-slide-group>

          <!-- empty -->
          <div v-else class="d-flex flex-column align-center mt-4">
            <span class="text-body-1 text-center subtext--text mb-4">
              Você não está participando de nenhuma equipe ativa
            </span>

            <v-btn
              class="rounded-l-0 rounded-r-lg text-none"
              color="primary"
              to="/leagues"
            >
              <v-icon left> mdi-arrow-right </v-icon>

              Participar
            </v-btn>
          </div>
        </v-card>
      </v-col>

      <!-- right column -->
      <v-col cols="12" sm="5" md="4" xl="3">
        <!-- account -->
        <v-card color="surface" class="pa-4 mb-6" flat>
          <div class="d-flex align-center flex-column">
            <v-avatar size="144" class="mb-4" tile>
              <v-img
                :src="
                  !!localUser && localUser.avatar
                    ? localUser.avatar
                    : require('@/assets/utils/profile.png')
                "
                contain
              />
            </v-avatar>

            <div class="text-h4 text-center font-weight-bold mb-2">
              <router-link to="/account">
                <span class="saira">
                  {{ localUser.nickname }}
                </span>
              </router-link>
            </div>
          </div>
        </v-card>

        <!-- games -->
        <v-card color="surface" class="pa-4 pb-6" flat>
          <div class="d-flex justify-space-between mb-6">
            <div class="text-h5 font-weight-bold">
              <span class="saira"> Meus jogos </span>
            </div>
          </div>

          <!-- loading -->
          <loader-content v-if="loading.games" />

          <!-- content -->
          <v-row v-else-if="myGames.length > 0">
            <v-col v-for="(item, i) in myGames" :key="i" cols="4" class="zoom">
              <v-card class="ma-1">
                <v-img :src="item.game.thumb" :aspect-ratio="4 / 4" />
              </v-card>
            </v-col>
          </v-row>

          <!-- empty -->
          <div v-else class="d-flex flex-column align-center">
            <span class="text-body-1 text-center subtext--text mb-4">
              Você ainda não cadastrou nenhum jogo.
            </span>

            <v-btn
              class="rounded-l-0 rounded-r-lg text-none"
              color="primary"
              to="/account/games"
            >
              <v-icon left> mdi-arrow-right </v-icon>

              Cadastrar
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { getUserGames } from "@/services/user";
import { getMyLeagues } from "@/services/leagues";
import { getMyTeams } from "@/services/teams";
import LastNews from "@/components/news/LastNews.vue";

export default {
  data() {
    return {
      loading: {
        leagues: true,
        teams: true,
        games: true,
      },
      myLeagues: [],
      myTeams: [],
      myGames: [],
    };
  },

  components: {
    LastNews,
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    ...mapState(["user", "localUser"]),
  },

  methods: {
    getData() {
      this.getMyLeagues();
      this.getMyTeams();
      this.getUserGames();
    },

    async getMyLeagues() {
      try {
        this.loading.leagues = true;

        const payload = {
          isActive: true,
        };

        await getMyLeagues(payload).then((res) => {
          this.myLeagues = res;
        });
      } catch (err) {
        // err
      } finally {
        this.loading.leagues = false;
      }
    },

    async getMyTeams() {
      try {
        this.loading.teams = true;

        await getMyTeams().then((res) => {
          this.myTeams = res;
        });
      } catch (err) {
        // err
      } finally {
        this.loading.teams = false;
      }
    },

    async getUserGames() {
      try {
        this.loading.games = true;

        await getUserGames().then((res) => {
          this.myGames = res.games;
        });
      } catch (err) {
        // err
      } finally {
        this.loading.games = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .v-slide-group__prev {
  min-width: 16px !important;
}

:deep() .v-slide-group__next {
  min-width: 16px !important;
}
</style>
